// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agreement-js": () => import("./../src/pages/agreement.js" /* webpackChunkName: "component---src-pages-agreement-js" */),
  "component---src-pages-business-js": () => import("./../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-callback-js": () => import("./../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-check-js": () => import("./../src/pages/check.js" /* webpackChunkName: "component---src-pages-check-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-js": () => import("./../src/pages/insurance.js" /* webpackChunkName: "component---src-pages-insurance-js" */),
  "component---src-pages-main-js": () => import("./../src/pages/main.js" /* webpackChunkName: "component---src-pages-main-js" */),
  "component---src-pages-new-fine-js": () => import("./../src/pages/newFine.js" /* webpackChunkName: "component---src-pages-new-fine-js" */),
  "component---src-pages-okauto-bot-offer-js": () => import("./../src/pages/okautoBotOffer.js" /* webpackChunkName: "component---src-pages-okauto-bot-offer-js" */),
  "component---src-pages-order-js": () => import("./../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-pay-js": () => import("./../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-photo-js": () => import("./../src/pages/photo.js" /* webpackChunkName: "component---src-pages-photo-js" */),
  "component---src-pages-policy-js": () => import("./../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-saqtanu-calc-new-js": () => import("./../src/pages/saqtanu-calc-new.js" /* webpackChunkName: "component---src-pages-saqtanu-calc-new-js" */),
  "component---src-pages-saqtanu-calc-js": () => import("./../src/pages/saqtanu-calc.js" /* webpackChunkName: "component---src-pages-saqtanu-calc-js" */),
  "component---src-pages-saqtanu-js": () => import("./../src/pages/saqtanu.js" /* webpackChunkName: "component---src-pages-saqtanu-js" */)
}

